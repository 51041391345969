import React from "react"
import { Form as SemanticForm, Label } from "semantic-ui-react"
import { Formik, Form, Field } from "formik"

const CustomInputComponent = ({
  field,
  form: { touched, errors },
  ...props
}) => {
  if (props.radio) {
    return (
      <SemanticForm.Field>
        <label style={{ marginBottom: "10px" }}>{props.label}</label>
        <SemanticForm.Radio
          label={field.value ? "Yes" : "No"}
          name={field.name}
          toggle
          checked={field.value}
          onChange={(e, d) =>
            field.onChange({
              target: {
                name: d.name,
                value: d.checked,
              },
            })
          }
        />
      </SemanticForm.Field>
    )
  }
  if (props.textarea) {
    return (
      <SemanticForm.Field>
        <SemanticForm.TextArea
          label={props.label}
          name={field.name}
          onBlur={field.onBlur}
          onChange={field.onChange}
          value={field.value}
          error={errors[field.name] && touched[field.name]}
        />
        {touched[field.name] && errors[field.name] ? (
          <Label
            basic
            // color="red"
            style={{ marginTop: "0.25em" }}
            pointing
          >
            {errors[field.name]}
          </Label>
        ) : null}
      </SemanticForm.Field>
    )
  }
  if (props.select) {
    return (
      <SemanticForm.Field>
        <SemanticForm.Select
          error={errors[field.name] && touched[field.name]}
          value={field.value}
          label={
            !props.noLabel
              ? `${props.label}${!props.required ? " (optional)" : ""}`
              : ""
          }
          onBlur={(e, d) =>
            field.onBlur({
              target: {
                name: d.name,
                value: d.value,
              },
            })
          }
          onChange={(e, d) =>
            field.onChange({
              target: {
                name: d.name,
                value: d.value,
              },
            })
          }
          options={props.options}
          placeholder={props.noLabel ? props.label : "Select One..."}
          name={field.name}
          search={props.search ? true : false}
        />
        {touched[field.name] && errors[field.name] ? (
          <Label
            basic
            // color="red"
            style={{ marginTop: "0.25em" }}
            pointing
          >
            {errors[field.name]}
          </Label>
        ) : null}
      </SemanticForm.Field>
    )
  }
  return (
    <SemanticForm.Field required>
      <SemanticForm.Input
        name={field.name}
        type="text"
        id={`${field.name}Field`}
        onBlur={field.onBlur}
        onChange={field.onChange}
        label={
          !props.noLabel
            ? `${props.label}${!props.required ? " (optional)" : ""}`
            : ""
        }
        placeholder={props.noLabel ? props.label : ""}
        value={field.value}
        error={errors[field.name] && touched[field.name]}
      />
      {touched[field.name] && errors[field.name] ? (
        <Label
          basic
          // color="red"
          style={{ marginTop: "0.25em" }}
          pointing
        >
          {errors[field.name]}
        </Label>
      ) : null}
    </SemanticForm.Field>
  )
}

export default CustomInputComponent
