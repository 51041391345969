import React from "react"
import { Icon } from "semantic-ui-react"

export default function FormSuccess() {
  return (
    <div style={{ textAlign: "center" }}>
      <Icon name="check circle" color="green" size="huge" />
      <p style={{ marginTop: "1em" }}>Your email has been sent. Thank you! We will contact you as soon as possible.</p>
    </div>
  )
}
