import React, { useState } from "react"
import axios from "axios"
import * as Yup from "yup"
import BasicForm from "@components/basicForm"
import { FormSuccess } from "./"

export default function RequestMoreInfo({ onSuccess }) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)

  function onSubmit(formData) {
    setError(null)
    setLoading(true)

    const req = {
      template_alias: "request-more-info",
      data: {
        name: `${formData.first_name} ${formData.last_name}`,
        email: formData.email,
        interest: formData.interest,
        comments: formData.comments,
      },
    }

    axios
      .post(`/enquiry/`, req)
      .then(() => {
        setSuccess(true)
      })
      .catch(err => {
        setLoading(false)
        setError(
          "Sorry, there was a problem submitting the form. Please try again."
        )

        setTimeout(() => {
          setError(null)
        }, 5000)
      })
  }

  const fields = [
    {
      grouped: true,
      fields: [
        {
          name: "first_name",
          label: "First Name",
          initialValue: "",
          required: true,
          schema: () => Yup.string().required("Required"),
        },
        {
          name: "last_name",
          label: "Last Name",
          initialValue: "",
          required: true,
          schema: () => Yup.string().required("Required"),
        },
      ],
    },
    {
      name: "email",
      label: "Email",
      initialValue: "",
      required: true,
      schema: () =>
        Yup.string()
          .email("Not a valid email")
          .required("Required"),
    },
    {
      name: "interest",
      label: "Product You're Requesting Information On?",
      initialValue: null,
      select: true,
      required: true,
      options: [
        { key: 1, text: "Antique Mirror", value: "Antique Mirror" },
        { key: 2, text: "Clear Textured Glass", value: "Clear Textured Glass" },
        { key: 3, text: "Back Painted Glass", value: "Back Painted Glass" },
        { key: 4, text: "Laminated Glass", value: "Laminated Glass" },
      ],
      schema: () =>
        Yup.string()
          .nullable("Required")
          .required("Required"),
    },
    {
      name: "comments",
      label: "Comments",
      initialValue: "",
      textarea: true,
      required: true,
      schema: () => Yup.string().required("Required"),
    },
  ]

  if (success) {
    return <FormSuccess />
  }

  return (
    <BasicForm
      loading={loading}
      error={error}
      onSubmit={onSubmit}
      fields={fields}
      buttonText="Submit"
    />
  )
}

RequestMoreInfo.defaultProps = {
  id: null,
  name: "",
  onSuccess: () => {},
}
