import React, { useState } from "react"
import * as Yup from "yup"
import axios from "axios"

import BasicForm from "@components/basicForm"
import { FormSuccess } from "./"

const fields = [
  {
    grouped: true,
    fields: [
      {
        name: "first_name",
        label: "First Name",
        initialValue: "",
        required: true,
        schema: () => Yup.string().required("Required"),
      },
      {
        name: "last_name",
        label: "Last Name",
        initialValue: "",
        required: true,
        schema: () => Yup.string().required("Required"),
      },
    ],
  },
  {
    name: "email",
    label: "Email",
    initialValue: "",
    required: true,
    schema: () =>
      Yup.string()
        .email("Not a valid email")
        .required("Required"),
  },
  {
    name: "comments",
    label: "Comments",
    initialValue: "",
    textarea: true,
    required: true,
    schema: () => Yup.string().required("Required"),
  },
]

export default function ContactForm() {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)

  function onSubmit(formData) {
    setLoading(true)

    const req = {
      template_alias: "contact-us-today",
      data: {
        name: `${formData.first_name} ${formData.last_name}`,
        email: formData.email,
        comments: formData.comments,
      },
    }

    axios
      .post(`/enquiry/`, req)
      .then(() => {
        setSuccess(true)
      })
      .catch(err => {
        setLoading(false)
        setError(
          "Sorry, there was a problem submitting the form. Please try again."
        )

        setTimeout(() => {
          setError(null)
        }, 5000)
      })
  }

  if (success) {
    return <FormSuccess />
  }

  return (
    <React.Fragment>
      <BasicForm
        fields={fields}
        onSubmit={onSubmit}
        loading={loading}
        buttonText="Submit"
      />
    </React.Fragment>
  )
}
