import React, { useState } from "react"
import { Formik, Form, Field } from "formik"
import * as Yup from "yup"
import { Button, Form as SemanticForm } from "semantic-ui-react"
import Recaptcha from "react-google-recaptcha"

import CustomInputComponent from "./CustomInputComponent"

export default function BasicForm(props) {
  const [recaptchaResponse, setRecaptchaResponse] = useState(null)

  let initialValues = {}
  let schemas = {}

  if (!props.fields.length) {
    return <p>No fields.</p>
  }

  props.fields.forEach(f => {
    if (f.grouped) {
      f.fields.forEach(field => {
        initialValues[field.name] = field.initialValue
        schemas[field.name] = field.schema()
      })
    } else {
      initialValues[f.name] = f.initialValue
      schemas[f.name] = f.schema()
    }
  })

  const schema = Yup.object().shape(schemas)

  const { loading } = props

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={(values, { resetForm }) => {
          props.onSubmit(values)
        }}
        enableReinitialize
      >
        {({ errors, touched }) => (
          <Form
            className={`basic-form ui form ${props.formSize} ${
              props.centered ? " centered" : ""
            }`}
          >
            {props.fields.map((f, i) => {
              if (f.grouped) {
                return (
                  <SemanticForm.Group widths="equal" key={i}>
                    {f.fields.map((fi, ii) => (
                      <Field
                        component={CustomInputComponent}
                        datepicker={fi.datepicker}
                        key={ii}
                        label={fi.label}
                        noLabel={fi.noLabel}
                        maxDate={fi.maxDate}
                        minDate={fi.minDate}
                        name={fi.name}
                        options={fi.options}
                        radio={fi.radio}
                        required={fi.required}
                        select={fi.select}
                        search={fi.search}
                        style={fi.style}
                        textarea={fi.textarea}
                      />
                    ))}
                  </SemanticForm.Group>
                )
              }

              return (
                <Field
                  component={CustomInputComponent}
                  datepicker={f.datepicker}
                  key={i}
                  label={f.label}
                  noLabel={f.noLabel}
                  maxDate={f.maxDate}
                  minDate={f.minDate}
                  name={f.name}
                  options={f.options}
                  radio={f.radio}
                  required={f.required}
                  select={f.select}
                  search={f.search}
                  style={f.style}
                  textarea={f.textarea}
                />
              )
            })}
            {props.error && <p className="form-error">{props.error}</p>}
            <div style={{ marginBottom: "1em" }}>
              <Recaptcha
                // ref="recaptcha"
                sitekey="6LeaQQkcAAAAAFlzzo1PfvMWDgFI4JUz4XqKlxoB"
                onChange={v => setRecaptchaResponse(v)}
              />
            </div>
            <div
              className="form-actions"
              className={`form-actions${props.centered ? " centered" : ""}`}
            >
              {props.children}
              <Button
                className="base"
                disabled={!recaptchaResponse || props.loading}
                loading={props.loading}
                content={props.buttonText}
                size={props.formSize}
                type="submit"
                {...props.buttonProps}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

BasicForm.defaultProps = {
  buttonProps: {},
  buttonStyle: {},
  buttonText: "Save",
  centered: false,
  fields: [],
  formSize: "small",
}
