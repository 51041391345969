import React, { useState } from "react"
import { Button, Modal } from "semantic-ui-react"

export default function FormWrapper({ buttonProps, children, modal }) {
  const [isOpen, setOpen] = useState(false)

  function onClose() {
    setOpen(false)
  }

  if (modal) {
    return (
      <React.Fragment>
        <Button {...buttonProps} onClick={() => setOpen(true)} />
        <Modal size="small" onClose={onClose} open={isOpen}>
          <Modal.Content>{children}</Modal.Content>
          <Modal.Actions>
            <Button content="Close" basic size="small" onClick={onClose} />
          </Modal.Actions>
        </Modal>
      </React.Fragment>
    )
  }

  return <div>{children}</div>
}

FormWrapper.defaultProps = {
  // button text for the button that opens the modal
  buttonProps: {
    content: "Click Me",
  },
  // wrap the form in a modal
  modal: false,
}
