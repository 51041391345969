import React, { useState } from "react"
import axios from "axios"
import * as Yup from "yup"
import BasicForm from "@components/basicForm"
import { FormSuccess } from "./"

import { phone } from "@utils/validators"

export default function RequestQuote({ onSuccess }) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)

  function onSubmit(formData) {
    setError(null)
    setLoading(true)

    const req = {
      template_alias: "request-quote",
      data: {
        name: `${formData.first_name} ${formData.last_name}`,
        company: formData.company,
        state: formData.state,
        industry: formData.industry,
        title: formData.title,
        email: formData.email,
        phone: formData.phone,
        comments: formData.comments,
      },
    }

    if (formData.other_location) {
      req.data.other_location = formData.other_location
    }

    axios
      .post(`/enquiry/`, req)
      .then(() => {
        setSuccess(true)
      })
      .catch(err => {
        setLoading(false)
        setError(
          "Sorry, there was a problem submitting the form. Please try again."
        )

        setTimeout(() => {
          setError(null)
        }, 5000)
      })
  }

  if (success) {
    return <FormSuccess />
  }

  const fields = [
    {
      grouped: true,
      fields: [
        {
          name: "first_name",
          label: "First Name",
          initialValue: "",
          required: true,
          schema: () => Yup.string().required("Required"),
        },
        {
          name: "last_name",
          label: "Last Name",
          initialValue: "",
          required: true,
          schema: () => Yup.string().required("Required"),
        },
      ],
    },
    {
      name: "company",
      label: "Company",
      initialValue: "",
      required: true,
      schema: () => Yup.string().required("Required"),
    },
    {
      name: "state",
      label: "State/Province",
      select: true,
      options: stateOptions,
      search: true,
      initialValue: "",
      required: true,
      schema: () =>
        Yup.string()
          .nullable()
          .required("Required"),
    },
    {
      name: "other_location",
      label: "If other, please specify",
      initialValue: "",
      required: false,
      schema: () => null,
    },
    {
      name: "industry",
      label: "Industry",
      select: true,
      options: industryOptions,
      search: true,
      initialValue: "",
      required: true,
      schema: () =>
        Yup.string()
          .nullable()
          .required("Required"),
    },
    {
      name: "title",
      label: "Title",
      initialValue: "",
      required: true,
      schema: () => Yup.string().required("Required"),
    },
    {
      name: "email",
      label: "Email",
      initialValue: "",
      required: true,
      schema: () =>
        Yup.string()
          .email("Not a valid email")
          .required("Required"),
    },
    {
      name: "phone",
      label: "Phone",
      initialValue: "",
      required: true,
      schema: () =>
        Yup.string()
          .matches(phone, "Not a valid phone number")
          .required("Required"),
    },
    {
      name: "comments",
      label: "Questions/Comment",
      initialValue: "",
      textarea: true,
      required: true,
      schema: () => Yup.string().required("Required"),
    },
  ]

  return (
    <BasicForm
      loading={loading}
      error={error}
      onSubmit={onSubmit}
      fields={fields}
      buttonText="Submit"
    />
  )
}

RequestQuote.defaultProps = {
  id: null,
  name: "",
  onSuccess: () => {},
}

const industryOptions = [
  { text: "Artist", value: "Artist" },
  {
    text: "Curtain Wall Facade Engineer",
    value: "Curtain Wall Facade Engineer",
  },
  { text: "Designer", value: "Designer" },
  { text: "Developer", value: "Developer" },
  { text: "Furniture", value: "Furniture" },
  { text: "General Contractor", value: "General Contractor" },
  {
    text: "Glass Distributors/Fabricator",
    value: "Glass Distributors/Fabricator",
  },
  { text: "Glass Shop", value: "Glass Shop" },
  { text: "Glazing Contractor", value: "Glazing Contractor" },
  { text: "Media", value: "Media" },
  { text: "OEM Manufacturers", value: "OEM Manufacturers" },
  { text: "Other", value: "Other" },
  { text: "Owner's Rep", value: "Owner's Rep" },
  {
    text: "Picture Frame Glass Distributor",
    value: "Picture Frame Glass Distributor",
  },
  {
    text: "Picture Frame Glass Manufacturer",
    value: "Picture Frame Glass Manufacturer",
  },
  { text: "Shower Doors", value: "Shower Doors" },
  { text: "Signs", value: "Signs" },
  { text: "Wall Company", value: "Wall Company" },
  { text: "Window and Doors", value: "Window and Doors" },
].map((m, i) => ({ ...m, key: i }))

const stateOptions = [
  "Alberta",
  "British Columbia",
  "Manitoba",
  "New Brunswick",
  "Newfoundland and Labrador",
  "Northwest Territories",
  "Nova Scotia",
  "Nunavut",
  "Ontario",
  "Prince Edward Island",
  "Quebec",
  "Saskatchewan",
  "Yukon Territory",
  "Alabama",
  "Alaska",
  "American Samoa",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District of Columbia",
  "Federated States of Micronesia",
  "Florida",
  "Georgia",
  "Guam",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Marshall Islands",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Northern Mariana Islands",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Palau",
  "Pennsylvania",
  "Puerto Rico",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virgin Island",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
]
  .sort((a, b) => (a > b ? 1 : -1))
  .map((m, i) => ({
    key: i,
    text: m,
    value: m,
  }))
